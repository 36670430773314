import React, { useState } from 'react';
import { Phone, Mail, MapPin, ExternalLink, Copy, Check } from 'lucide-react';

const CopyAlert = ({ show }) => {
  if (!show) return null;
  
  return (
    <div className="fixed top-4 right-4 bg-green-100 border border-green-500 text-green-700 px-4 py-3 rounded shadow-lg z-50 animate-fadeIn">
      <div className="flex items-center">
        <Check className="h-4 w-4 mr-2" />
        <span>¡Copiado al portapapeles!</span>
      </div>
    </div>
  );
};

const ContactSection = () => {
  const [showCopied, setShowCopied] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);

  const contactInfo = [
    {
      icon: Phone,
      title: "Teléfono",
      content: ["+51 936 804 211", "01-7540464"],
      copyable: true
    },
    {
      icon: Mail,
      title: "Email",
      content: ["transportes@gruposbc.com.pe"],
      copyable: true
    },
    {
      icon: MapPin,
      title: "Dirección",
      content: [
        "Oficina: av. elmer faucet Nro. 6000 prov. const. del callao - prov. const. del callao - callao (2do piso) Oficina 210",
        "Lima, Perú"
      ],
      copyable: true
    }
  ];

  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <div className="bg-gradient-to-r from-blue-50 via-white to-blue-50 py-16">
      <div className="max-w-7xl mx-auto px-6">
        <h2 className="text-4xl font-bold text-center text-gray-800 mb-12 hover:text-blue-600 transition-colors duration-300">
          Contáctenos
        </h2>

        <CopyAlert show={showCopied} />
        
        <div className="grid md:grid-cols-3 gap-8 mb-12">
          {contactInfo.map((info, index) => (
            <div
              key={index}
              className={`
                relative overflow-hidden
                flex flex-col items-center p-6 
                bg-white rounded-lg shadow-lg
                transform transition-all duration-300 ease-in-out
                ${hoveredCard === index ? 'scale-105 shadow-xl' : 'scale-100'}
              `}
              onMouseEnter={() => setHoveredCard(index)}
              onMouseLeave={() => setHoveredCard(null)}
            >
              <div className={`
                absolute inset-0 bg-blue-50 
                transition-opacity duration-300
                ${hoveredCard === index ? 'opacity-10' : 'opacity-0'}
              `} />
              
              <info.icon 
                className={`
                  h-12 w-12 text-blue-600 mb-4
                  transform transition-transform duration-500
                  ${hoveredCard === index ? 'scale-110 rotate-12' : ''}
                `}
              />
              
              <h3 className="text-xl font-semibold mb-4 text-gray-800">
                {info.title}
              </h3>
              
              <div className="space-y-2 text-center">
                {info.content.map((text, idx) => (
                  <div 
                    key={idx}
                    className="group relative flex items-center justify-center"
                  >
                    <p className="text-gray-600">{text}</p>
                    {info.copyable && (
                      <button
                        onClick={() => handleCopy(text)}
                        className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                        title="Copiar al portapapeles"
                      >
                        <Copy className="h-4 w-4 text-blue-600 hover:text-blue-800" />
                      </button>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-12">
          <h3 className="text-2xl font-semibold text-gray-800 text-center mb-6">
            Nuestra Ubicación
          </h3>
          <div className="relative group">
            <div className={`
              rounded-lg overflow-hidden shadow-lg 
              transform transition-all duration-500
              ${mapLoaded ? 'opacity-100' : 'opacity-0'}
              group-hover:shadow-2xl
            `}>
              <div className="relative">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4197.403666325202!2d-77.12939031843936!3d-11.997271157902189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9105cdca638d462b%3A0xa65e68b2fbb40d63!2sAv.%20Elmer%20Faucett%206000%2C%20Callao%2007031!5e1!3m2!1ses!2spe!4v1730765328098!5m2!1ses!2spe"
                  className="w-full h-[450px] md:h-[600px]"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  onLoad={() => setMapLoaded(true)}
                />
                <a
                  href="https://www.google.com/maps?ll=-11.997271,-77.129390&z=16&t=h&hl=es&gl=PE&mapclient=embed&q=Av.+Elmer+Faucett+6000+Callao+07031"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="
                    absolute bottom-4 right-4 
                    bg-white px-4 py-2 rounded-full
                    shadow-md flex items-center gap-2
                    transform transition-transform duration-300
                    hover:scale-105 hover:bg-blue-50
                  "
                >
                  <span>Ver en Google Maps</span>
                  <ExternalLink className="h-4 w-4" />
                </a>
              </div>
            </div>
            {!mapLoaded && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="w-12 h-12 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
              </div>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }
        
        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out;
        }
      `}</style>
    </div>
  );
};

export default ContactSection;