// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import HeroSection from './components/home/HeroSection';
import ServicesSection from './components/home/ServicesSection';
import Formulario from './components/shared/Formulario';
import ContactSection from './components/home/ContactSection';
import WhatsappFloat from './components/shared/Whatsapp';
import './App.css';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <Routes>
          <Route 
            path="/" 
            element={
              <main className="flex-1">
                <ServicesSection />
              </main>
            } 
          />
          
          <Route 
            path="/contacto" 
            element={
              <main className="flex-1">
                <Formulario />
              </main>
            }
          />
          
          <Route 
            path="/contactenos" 
            element={
              <main className="flex-1">
                <ContactSection />
              </main>
            }
          />
        </Routes>
        <Footer />
        <WhatsappFloat /> 
      </div>
    </Router>
  );
}

export default App;