import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Settings, Wrench, Menu, X, Mail, Phone } from 'lucide-react';
import logoSBC from '../../assets/images/logo/SBC_TRANSPORT_Logo-removebg-preview.png';
import navbarBg from '../../assets/images/backgrounds/sbc_Fondo.png';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path) => location.pathname === path;

  const menuItems = [
    { path: '/', text: 'Inicio' },
    { path: '/contactenos', text: 'Contáctenos' }
  ];

  return (
    <>
      <nav className="w-full">
        {/* Top Bar */}
        <div className="bg-[#006D88] text-white">
      <div className="flex flex-col sm:flex-row sm:justify-between items-center px-6 py-2 max-w-[1920px] mx-auto space-y-1 sm:space-y-0">
        <div className="flex items-center space-x-2 text-xs sm:text-sm">
          <Mail className="w-5 h-5 text-yellow-300 animate-pulse" />
          <span className="whitespace-nowrap">
            <span className="font-semibold">Email:</span>{' '}
            <a href="mailto:transportes@gruposbc.com.pe" className="hover:underline text-yellow-100">
              transportes@gruposbc.com.pe
            </a>
          </span>
        </div>
        <div className="flex items-center space-x-2 text-xs sm:text-sm">
          <Phone className="w-5 h-5 text-yellow-300 animate-pulse" />
          <span>
            <span className="font-semibold">Tel:</span>{' '}
            <a href="tel:+51936804211" className="hover:underline text-yellow-100">
              +51 936 804 211
            </a>
          </span>
        </div>
      </div>
      <div className="h-1 bg-gradient-to-r from-yellow-400 via-yellow-300 to-yellow-400"></div>
    </div>

        {/* Main Navbar */}
        <div className="bg-white shadow-md">
          <div className="flex justify-between items-center px-4 py-2 max-w-[1920px] mx-auto">
            <Link to="/" className="flex-shrink-0">
              <img 
                src={logoSBC} 
                alt="SBC Transport Logo" 
                className="h-10 sm:h-12 md:h-14 w-auto object-contain"
              />
            </Link>

            <div className="hidden md:flex space-x-6">
              {menuItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`text-base font-medium transition-colors duration-200 ${
                    isActive(item.path)
                      ? 'text-[#006D88]'
                      : 'text-gray-700 hover:text-[#006D88]'
                  }`}
                >
                  {item.text}
                </Link>
              ))}
            </div>

            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden p-2 text-gray-700 hover:text-[#006D88]"
            >
              {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>

          {isMenuOpen && (
            <div className="md:hidden border-t border-gray-100">
              {menuItems.map((item) => (
                <Link
                  key={item.path}
                  to={item.path}
                  onClick={() => setIsMenuOpen(false)}
                  className={`block px-4 py-3 text-base transition-colors duration-200 ${
                    isActive(item.path)
                      ? 'bg-[#E0F5FA] text-[#006D88]'
                      : 'text-gray-700 hover:bg-[#E0F5FA] hover:text-[#006D88]'
                  }`}
                >
                  {item.text}
                </Link>
              ))}
            </div>
          )}
        </div>
      </nav>

      {/* Hero Section */}
      {location.pathname === '/' && (
        <div className="relative h-[calc(100vh-124px)]">
          <div 
            className="absolute inset-0 w-full h-full bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${navbarBg})`
            }}
          >
            <div className="absolute inset-0 bg-black/50" />
          </div>

          <div className="relative h-full flex flex-col">
            <div className="flex-1 flex flex-col items-center justify-center text-white px-4">
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center">
                Soluciones de Transporte Confiables
              </h1>
              <p className="mt-4 text-sm sm:text-base md:text-lg text-center max-w-2xl">
                Ofrecemos servicios de transporte seguros y eficientes para satisfacer todas tus necesidades logísticas
              </p>
            </div>

            <div className="bg-gradient-to-r from-yellow-500 via-yellow-400 to-yellow-500">
              <div className="flex items-center justify-center gap-2 sm:gap-4 py-2 px-4">
                <Settings className="hidden sm:block h-5 w-5 animate-spin text-blue-600" />
                <div className="flex flex-col items-center">
                  <p className="text-sm sm:text-base font-medium text-blue-800 text-center">
                    Estamos trabajando en nuestro sitio web para ti
                  </p>
                  <div className="flex items-center gap-1 sm:gap-2 mt-1">
                    <Wrench className="h-4 w-4 animate-bounce text-blue-700" />
                    <span className="text-xs sm:text-sm text-blue-700">
                      Próximamente nuevas funcionalidades
                    </span>
                  </div>
                </div>
                <Settings className="hidden sm:block h-5 w-5 animate-spin text-blue-600" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
