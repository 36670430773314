import { FaWhatsapp } from 'react-icons/fa';

const WhatsappFloat = () => {
  return (
    <div className="fixed bottom-4 right-4 z-10 flex flex-col items-center">
      <a
        href="https://wa.me/51936804211"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-green-500 p-3 rounded-full shadow-lg transform transition duration-300 hover:scale-105"
      >
        <FaWhatsapp className="h-6 w-6 text-white" />
      </a>
      <span className="mt-2 text-green-600 font-semibold text-sm opacity-0 animate-fade-in-up">
        Sbc-Transport
      </span>
      <style jsx>{`
        @keyframes fadeInUp {
          0% {
            opacity: 0;
            transform: translateY(10px);
          }
          100% {
            opacity: 1;
            transform: translateY(0);
          }
        }
        .animate-fade-in-up {
          animation: fadeInUp 0.5s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

export default WhatsappFloat;
