import React from 'react';

import client1 from '../../assets/images/clients/cliente1.png';
import client2 from '../../assets/images/clients/fargoline_sa_logo.jpeg';
import client3 from '../../assets/images/clients/schryver.png';
import cliente4 from '../../assets/images/clients/dp wo logo.png';
import cliente5 from '../../assets/images/clients/itaw logo.png';
import cliente6 from '../../assets/images/clients/logo kline.png';
import cliente7 from '../../assets/images/clients/transtainer_regional_logo.jpg';

const ServicesSection = () => {
  const clients = [
    { name: "Cliente 1", logo: client1 },
    { name: "Cliente 2", logo: client2 },
    { name: "Cliente 3", logo: client3 },
    { name: "Cliente 4", logo: cliente4 },
    { name: "Cliente 5", logo: cliente5 },
    { name: "Cliente 6", logo: cliente6 },
    { name: "Cliente 7", logo: cliente7 },
  ];

  return (
    <section className="bg-gray-50 py-12 md:py-20">
      <div className="container mx-auto px-4 md:px-8 max-w-7xl">
        <h2 className="text-3xl md:text-5xl font-bold text-center text-[#006D88] mb-12 md:mb-16">
          Nuestros Clientes
        </h2>

        <div className="flex justify-center">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 md:gap-8 w-full max-w-6xl">
            {clients.map((client, index) => (
              <div 
                key={index} 
                className="flex items-center justify-center bg-white rounded-xl shadow-md hover:shadow-xl transition-all duration-300 p-6 aspect-square"
              >
                <div className="relative w-full h-full flex items-center justify-center p-4">
                  <img 
                    src={client.logo} 
                    alt={`Logo ${client.name}`}
                    className="max-w-full max-h-full object-contain transition-transform duration-300 hover:scale-110"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
